<template>
	<div class="box">
		<div class="row">
			<div class="col-12">
				<h2>{{ $t('pax.title_translations') }}</h2>

				<LoadingSpinner v-if="processing" />
				<template v-else>
					<div v-for="translation in all_translations" :key="translation.translationcontent_code" class="mb-3 px-3 py-2 border rounded">
						<div class="row align-items-center">
							<div class="col">
								<b>{{ $t('pax.'+translation.translationcontent_code) }}</b>
							</div>
							<div class="col-auto">
		        				<button class="btn btn-primary" @click="openModalEdit(translation.translationcontent_code)">{{ $t('global.modifier') }} <font-awesome-icon :icon="['fal', 'angle-right']" /></button>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>

		<b-modal size="xl" ref="modal-translation" hide-footer>
            <template v-slot:modal-title>
                {{ $t("pax.edit_translation") }} - {{ $t('pax.'+translation_edit.translationcontent_code) }}
            </template>
            <div v-if="translation_edit" class="row justify-content-around px-3">
            	<b-tabs content-class="mt-3">
					<b-tab
						v-for="locale in locales"
						:key="locale.code"
						:title="locale.title"
						:active="locale.code === localeSelected.code"
						title-link-class="border"
					>
						<div class="row">
							<div class="col-12">
								<label>{{ $t('pax.contenu') }}</label>
								<e-editor-froala
					                :placeholder="getTrad('pax.translations_description')"
					                :content="translation_edit.lang[locale.code].translationcontent_content"
					                @change="updateContent($event, locale.code)"
					            ></e-editor-froala>
					        </div>
					        <div class="col-12 mt-2 text-center">
					        	<button class="btn btn-primary" @click="saveTranslationLang(locale.code)">{{ $t('global.enregistrer') }} <font-awesome-icon v-if="processing_translation" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /></button>
					        </div>
				        </div>
					</b-tab>
				</b-tabs>
            </div>
        </b-modal>
	</div>
</template>

<script type="text/javascript">
	import TranslationContent from "@/mixins/TranslationContent.js"

	export default {
		name: 'PAXTraduction',
		mixins: [TranslationContent],
		data () {
			return {
				processing: false,
				all_translations: [],
				translation_edit: null,
				locales: [],
				localeSelected: {
					title: this.$t('global.lang_fr'),
					code: 'fr'
				},
				processing_translation: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.processing = true
				this.all_translations = await this.getTranslations(['pax_introduction', 'pax_reverse', 'pax_express'])
				this.processing = false
				this.locales = this.getLocales()
			},

			async openModalEdit(code) {
				const translations = await this.getTranslationsByCode(code)

				this.translation_edit = {
					translationcontent_code: code,
					lang: {
						'fr': translations.find(lang => lang.translationcontent_lang == 'fr'),
						'en': translations.find(lang => lang.translationcontent_lang == 'en'),
						'es': translations.find(lang => lang.translationcontent_lang == 'es')
					}
				}

				this.$refs['modal-translation'].show()
			},

			updateContent(e, lang) {
				this.translation_edit.lang[lang].translationcontent_content = e.val
			},

			async saveTranslationLang(lang) {
				this.processing_translation = true
				const params = {
					content: this.translation_edit.lang[lang].translationcontent_content
				}
				await this.saveTranslationByCodeAndLang(this.translation_edit.translationcontent_code, lang, params)
				this.processing_translation = false
				this.successToast('toast.info_save_succes')
			}
		},

		components: {
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
		}
	}

</script>